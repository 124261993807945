import React from "react";
import "./style.scss";
import EventsImg from "../../assets/images/events/eventsBanner.png";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const EventsAndActivities = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      <section className="mb-md-5">
        <SecondaryBanner
          imageUrl={EventsImg}
          title="University Life - Events & Activities"
          desc="Discover a Vibrant Community Beyond the Classroom."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-5">
                Stars in Motions, Legends in the Making
              </h1>
            </div>
            <div className="col-12">
              <p className="global-bodytext mb-4">
                <b>Clubs </b>
                Dive into a world of creativity, innovation, and leadership.
                Whether it’s tech, art, music, or social change, our clubs are
                the launchpads for your boldest ideas. Get ready to lead,
                connect, and make your mark.
              </p>
              <p className="global-bodytext mb-4">
                <b>Sports </b>
                At Sitare, we play to win, but more importantly, we play to
                grow. From fierce competition to unbeatable camaraderie, our
                sports teams push limits and build champions. Together, we rise,
                we compete, we conquer.
              </p>

              <p className="global-bodytext mb-4">
                At Sitare, we don’t just follow the path, we create it.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container py-lg-5 mb-5 se-cards">
        <MenuCards
          data={EVENTS_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--orange-theme"
        />
      </section>
    </>
  );
};

export default EventsAndActivities;
import React from 'react'
import "./index.scss"
import { useNavigate } from 'react-router-dom'

const SecondaryBanner = ({imageUrl, title, desc, themeColor, textColor="text-black"}) => {
  const navigate = useNavigate();

  return (
    <div className='sh-contentimgsplit'>
        <div className={`sh-contentimgsplit--content ${themeColor}`}>
          <div className='sh-contentimgsplit--contain'>
          <button className={`sh-contentimgsplit--btn ${textColor} ${textColor == "text-white" && "white-border"}`} onClick={() => navigate(-1)}>
            <i class={`fa-solid fa-arrow-left sh-contentimgsplit--btn-arrow`}></i>
          </button>
          <p className={`sh-contentimgsplit--content-title ${textColor}`}>{title}</p>
          <p className={`sh-contentimgsplit--content-desc ${textColor}`}>{desc}</p>
          </div>
        </div>
        <div className='sh-contentimgsplit--image'>
            <img className='sh-contentimgsplit--imageimg' src={imageUrl} alt='banner' draggable="false"/>
        </div>
    </div>
  )
}

export default SecondaryBanner;
// import logo from './logo.svg';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../src/assets/styles/styles.scss';
import './App.css';
import HomePage from "../src/pages/home";
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import Curriculum from './pages/curriculum';
import WhoWeAre from './pages/whoWeAre';
import OurPeople from './pages/ourPeople';
import MainLayout from "../src/components/layout/MainLayout";
import WorkWithUs from './pages/workWithUs';
import AcademicLife from './pages/academicLife';
import AcademicVacancies from './pages/academicVacancies';
import AdministrativeVacancies from './pages/administrativeVacancies';
import StudentProjects from './pages/studentProjects';
import Hackathon from './pages/Hackathon';
import Internships from './pages/internships';
import PageNotFound from './pages/pageNotFound';
import Blogs from './pages/blogs';
import InnerBlogs from './pages/innerBlogs';
import WhySitare from './pages/whySitare';
import Projects from './pages/projects';
import EventsAndActivities from './pages/eventsAndActivities';
import ClassActivities from './pages/classActivities';
import PastActivities from './pages/pastActivities';
import UpcomingActivities from './pages/upcomingActivities';
import EventsInauguration from './pages/eventsInauguration';
import UniversityLife from './pages/universityLife';
import Facilities from './pages/Facilities';
import NewsAndUpdates from './pages/newsAndUpdates';
import HostelsPage from './pages/hostels';
import Cafeteria from './pages/Cafeteria';
import GymPage from './pages/gym';
import SportsPage from './pages/sports';
import Auditorium from './pages/auditorium';
import ClubsPage from './pages/clubs';
import FestsPage from './pages/Fests';
import EventSportsPage from './pages/EventSports';
import FaculityProjects from './pages/facultyProjects';
import TransformationNewArticles from './pages/transformation-new-articles';
import Placements from './pages/placements';
import InternshipOneYear from './pages/internshipOneYear';
import InspiringStories from './pages/inspiringStories';
import NewsArticles from './pages/newsArticles';
import InternshipThreeMonth from './pages/internshipThreeMonth';
import JobAfterGraduation from './pages/jobAfterGraduation';
import TransformationStories from './pages/transformationStories';
import AlumniPage from './pages/alumni';
import AlumniBenefits from './pages/alumni-benefits';
import AlumniStayConnected from './pages/alumni-stay-connected';
import AlumniEventsAndReunion from './pages/alumni-events-and-reunion';
import AlumniGallery from './pages/alumni-gallery';
import StayConnected from './pages/stayConnected';
import UniversityEvent from './pages/university-events';
import AboutGallery from './pages/about-gallery';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
        <Route path="/contact-us" element={<MainLayout><ContactUs /></MainLayout>} />
        <Route path="/why-sitare" element={<MainLayout><AboutUs /></MainLayout>} />
        <Route path="/who-we-are" element={<MainLayout><WhoWeAre /></MainLayout>} />
        <Route path="/our-people" element={<MainLayout><OurPeople /></MainLayout>} />
        <Route path="/work-with-us" element={<MainLayout><WorkWithUs /></MainLayout>} />
        <Route path="/academic-vacancies" element={<MainLayout><AcademicVacancies /></MainLayout>} />
        <Route path="/administrative-vacancies" element={<MainLayout><AdministrativeVacancies /></MainLayout>} />
        <Route path="/academic-Life" element={<MainLayout><AcademicLife /></MainLayout>} />
        <Route path="/student-projects" element={<MainLayout><StudentProjects /></MainLayout>} />
        {/* <Route path="/Hackathon" element={<MainLayout><Hackathon /></MainLayout>} /> */}
        <Route path="/internships" element={<MainLayout><Internships /></MainLayout>} />
        <Route path="/curriculum" element={<MainLayout><Curriculum /></MainLayout>} />
        <Route path="/student-projects" element={<MainLayout><StudentProjects /></MainLayout>} />
        <Route path="/hackathon" element={<MainLayout><Hackathon /></MainLayout>} />
        <Route path="/blogs" element={<MainLayout><Blogs /></MainLayout>} />
        <Route path="/blogs/inner-blogs" element={<MainLayout><InnerBlogs /></MainLayout>} />
        <Route path="/why-sitare" element={<MainLayout><WhySitare /></MainLayout>} />
        <Route path="/projects" element={<MainLayout><Projects /></MainLayout>} />
        <Route path="/events-activities" element={<MainLayout><EventsAndActivities /></MainLayout>} />
        <Route path="/class-activities" element={<MainLayout><ClassActivities /></MainLayout>} />
        <Route path="/past-activities" element={<MainLayout><PastActivities /></MainLayout>} />
        <Route path="/upcoming-activities" element={<MainLayout><UpcomingActivities /></MainLayout>} />
        <Route path="/events-inauguration" element={<MainLayout><EventsInauguration /></MainLayout>} />
        <Route path="/university-life" element={<MainLayout><UniversityLife /></MainLayout>} />
        <Route path="/facilities" element={<MainLayout><Facilities /></MainLayout>} />
        <Route path="/news-updates" element={<MainLayout><NewsAndUpdates /></MainLayout>} />
        <Route path="/hostels" element={<MainLayout><HostelsPage /></MainLayout>} />
        <Route path="/cafeteria" element={<MainLayout><Cafeteria /></MainLayout>} />
        <Route path="/gym" element={<MainLayout><GymPage /></MainLayout>} />
        <Route path="/sports" element={<MainLayout><SportsPage /></MainLayout>} />
        <Route path="/auditorium" element={<MainLayout><Auditorium /></MainLayout>} />
        <Route path="/clubs" element={<MainLayout><ClubsPage /></MainLayout>} />
        <Route path="/fests" element={<MainLayout><FestsPage /></MainLayout>} />
        <Route path="/eventsports" element={<MainLayout><EventSportsPage /></MainLayout>} />
        <Route path="/faculty-projects" element={<MainLayout><FaculityProjects /></MainLayout>} />
        <Route path="/transformation-new-articles" element={<MainLayout><TransformationNewArticles /></MainLayout>} />
        <Route path="/placements" element={<MainLayout><Placements /></MainLayout>} />
        <Route path="/internship-one-year" element={<MainLayout><InternshipOneYear /></MainLayout>} />
        <Route path="/inspiring-stories" element={<MainLayout><InspiringStories /></MainLayout>} />
        <Route path="/news-articles" element={<MainLayout><NewsArticles /></MainLayout>} />
        <Route path="/internship-three-month" element={<MainLayout><InternshipThreeMonth /></MainLayout>} />
        <Route path="/job-after-graduation" element={<MainLayout><JobAfterGraduation /></MainLayout>} />
        <Route path="/transformation-stories" element={<MainLayout><TransformationStories /></MainLayout>} />
        <Route path="/alumni" element={<MainLayout><AlumniPage /></MainLayout>} />
        <Route path="/alumni-benefits" element={<MainLayout><AlumniBenefits /></MainLayout>} />
        <Route path="/alumni-stay-connected" element={<MainLayout><AlumniStayConnected /></MainLayout>} />
        <Route path="/alumni-events" element={<MainLayout><AlumniEventsAndReunion /></MainLayout>} />
        <Route path="/alumni-gallery" element={<MainLayout><AlumniGallery /></MainLayout>} />
        <Route path="/stay-connected" element={<MainLayout><StayConnected /></MainLayout>} />
        <Route path="/university-event" element={<MainLayout><UniversityEvent /></MainLayout>} />
        <Route path="/about-gallery" element={<MainLayout><AboutGallery /></MainLayout>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
// import auditoriumbanner from "../../assets/images/university/auditoriumBanner.png";
import auditoriumbanner from "../../assets/images/university/auditorium_3.jpg";

// import auditorium1 from "../../assets/images/auditorium/a1.png";
// import auditorium2 from "../../assets/images/auditorium/a2.png";
// import auditorium3 from "../../assets/images/auditorium/a3.png";
// import auditorium4 from "../../assets/images/auditorium/a4.png";
// import auditorium5 from "../../assets/images/auditorium/a5.png";
// import auditorium6 from "../../assets/images/auditorium/a6.png";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const Auditorium = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if(route.includes("http:") || route.includes("https:")){
      window.open(route, "_blank")
    }else{
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={auditoriumbanner}
          title="University Life - Facilities - Auditorium"
          desc="Inspiring Events, Unforgettable Experiences."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
              Where Ideas and Performances Come to Life
              </h1>
              <p className="global-bodytext">
              Our auditorium is the venue for lectures, seminars, cultural performances, and student activities. It is designed to host large events and provides a platform for students to showcase their talents or attend inspiring talks and presentations.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Facilites */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                  Spacious & Modern
                  </b>
                  <p className="global-bodytext">
                  A large, well-equipped auditorium with seating for several hundred people.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                  Advanced AV Systems
                  </b>
                  <p className="global-bodytext">
                  Equipped with high-quality sound systems, projectors, and lighting for diverse events.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                  Multifunctional
                  </b>
                  <p className="global-bodytext mb-3">
                  Ideal for academic events, student performances, conferences, and guest lectures.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                  How to book?
                  </b>
                  <p className="global-bodytext">
                  Contact our administrative office for more details.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Images */}
      {/* <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="ul-imgcards">
              <img src={auditorium1} alt="images" draggable="false" />
              <img src={auditorium2} alt="images" draggable="false" />
              <img src={auditorium3} alt="images" draggable="false" />
              <img src={auditorium4} alt="images" draggable="false" />
              <img src={auditorium5} alt="images" draggable="false" />
              <img src={auditorium6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section> */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={FACILITIES_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Auditorium;

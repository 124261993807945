import React from 'react';
import InspiringStoriesDetail from './InspiringStoriesDetail';
import shravanImage from '../../assets/images/placement/story1.png';
import divyanshImage from '../../assets/images/placement/story2.png';
import ashutoshImage from '../../assets/images/placement/story3.png';


const images = {
    shravan: shravanImage,
    divyansh: divyanshImage,
    ashutosh: ashutoshImage
};

const InspiringStoriesComp = ({data}) => {
  return (
    <>
      
            <li className='global-newscard--list global-newscard--listtwo'>
                <div className='global-newscard--listimage'>
                    <img 
                        src={images[data?.image]} 
                        alt="story image" draggable="false" className='rounded-0'/>
                </div>
                <div className='global-newscard--listcontent'>
                    <h3 className="global-newscard--listtitle">{data?.studentName}</h3>
                    <h3 className="global-newscard--listsubtitle">{data?.studentBatch}</h3>
                    <p className="global-bodytext global-newscard--listdesc">
                        {data?.desc}
                    </p>

                    <button className='global-newscard--listbtn' data-bs-toggle="offcanvas" data-bs-target={`#InspiringStoriesDetail${data?.id}`} aria-controls={`InspiringStoriesDetail${data?.id}`} >Read More</button>
                </div>
            </li>
      
        <InspiringStoriesDetail item={data}  />
    </>
  )
}

export default InspiringStoriesComp
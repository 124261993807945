import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import INDUSTRY_CONNECTIONS from "../../dummyData/IndustryConnections.json";
import '../../assets/styles/_common_owlCarousel.scss';
import auto_nxt from '../../assets/images/industry/auto_nxt.png'
import beans from '../../assets/images/industry/beans.png'
import chai_point from '../../assets/images/industry/chai_point.png'
import digii from '../../assets/images/industry/digii.png'
import driveu from '../../assets/images/industry/driveu.png'
import first_hive from '../../assets/images/industry/first_hive.png'
import glean from '../../assets/images/industry/glean.png'
import goqii from '../../assets/images/industry/goqii.png'
import goveva from '../../assets/images/industry/goveva.png'
import hiration from '../../assets/images/industry/hiration.png'
import kloudle from '../../assets/images/industry/kloudle.png'
import mathisys from '../../assets/images/industry/mathisys.png'
import med_piper from '../../assets/images/industry/med_piper.png'
import moglix from '../../assets/images/industry/moglix.png'
import proshort from '../../assets/images/industry/proshort.png'
import psi from '../../assets/images/industry/psi.png'
import super_tails from '../../assets/images/industry/super_tails.png'
import tracxn from '../../assets/images/industry/tracxn.png'
import yulu from '../../assets/images/industry/yulu.png'
import zeni from '../../assets/images/industry/zeni.png'
import collage1 from '../../assets/images/industry/collage1.png'
import collage2 from '../../assets/images/industry/collage2.png'
import collage3 from '../../assets/images/industry/collage3.png'
import collage4 from '../../assets/images/industry/collage4.png'
import collage5 from '../../assets/images/industry/collage5.png'

const IND_IMG = {
    auto_nxt,
    beans,
    chai_point,
    digii,
    driveu,
    first_hive,
    glean,
    goqii,
    goveva,
    hiration,
    kloudle,
    mathisys,
    med_piper,
    moglix,
    proshort,
    psi,
    super_tails,
    tracxn,
    yulu,
    zeni
}

const COLLAGE_ITEMS = [
    collage1, collage2, collage3, collage4, collage5
]

const CollageCarousel = ({data}) => {

    return (
        <>
            <OwlCarousel
                id='cc_carousel'
                items={1}
                className="cc-collage--owl-theme"
                loop
                nav
                dotClass="cc-collage--carousel-dot-wrapper--carousel-dot"
                dotsClass="cc-collage--carousel-dot-wrapper"
                navIcon={["PREV", "NEXT"]}
                margin={12}
                responsive={{
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 1,
                        margin: 20,
                    }
                }}
            >
                        
                {COLLAGE_ITEMS &&
                    COLLAGE_ITEMS.length > 0 &&
                    COLLAGE_ITEMS.map((imagePath, imgInd) => (
                    <div
                        key={imgInd}
                        className="cc-collage--owl-theme--griditem"
                    >
                        <img className="img w-100" 
                            src={imagePath}
                            alt="image" draggable="false" 
                        />
                    </div>
                ))}
            </OwlCarousel>
            <div className='cc-collage--list' id='cc_grid'>
                {data &&
                    data.length > 0 &&
                    data.map((imageItem, imgInd) => (
                        <div
                            key={imgInd}
                            className="cc-collage--listitem"
                        >
                            <img className="img w-100" 
                                src={IND_IMG[imageItem]}
                                alt="image" draggable="false" 
                            />
                        </div>
                    ))}
            </div>
        </>
    )
}

export default CollageCarousel
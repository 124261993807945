import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import sportbanner from "../../assets/images/university/sportbanner.jpg";
import sport1 from "../../assets/images/sports/s1.png";
import sport2 from "../../assets/images/sports/s2.png";
import sport3 from "../../assets/images/sports/s3.png";
import sport4 from "../../assets/images/sports/s4.png";
import sport5 from "../../assets/images/sports/s5.png";
import sport6 from "../../assets/images/sports/s6.png";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const SportsPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={sportbanner}
          title="University Life - Facilities - Sports"
          desc="Unleash Your Potential On and Off the Field."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Play, Compete, and Thrive
              </h1>
              <p className="global-bodytext">
                Sports are an integral part of student life at Sitare University. Whether you’re a serious athlete or just want to unwind after a day of studying, our campus offers facilities for a variety of sports.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Facilites */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    Outdoor Fields
                  </b>
                  <p className="global-bodytext">
                    Well-maintained fields for football, cricket, and athletics.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Indoor Courts
                  </b>
                  <p className="global-bodytext">
                    Facilities for basketball, badminton, and table tennis.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Recreational Activities
                  </b>
                  <p className="global-bodytext mb-3">
                    A range of casual games and sports clubs that encourage teamwork, leadership, and a healthy lifestyle.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Sports Facilities Timings
                  </b>
                  <p className="global-bodytext">
                    Outdoor fields and indoor courts are available all throughout the day.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Images */}
      {/* <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="ul-imgcards">
              <img src={sport1} alt="images" draggable="false" />
              <img src={sport2} alt="images" draggable="false" />
              <img src={sport3} alt="images" draggable="false" />
              <img src={sport4} alt="images" draggable="false" />
              <img src={sport5} alt="images" draggable="false" />
              <img src={sport6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section> */}

      {/* TODO(akshi): Add University Life related menu cards here */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={FACILITIES_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SportsPage;

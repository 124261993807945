import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import GalleryBanner from "../../assets/images/about/galleryBanner.png";
import gallery1 from "../../assets/images/about_gallery/g1.JPG";
import gallery2 from "../../assets/images/about_gallery/g2.JPG";
import gallery3 from "../../assets/images/about_gallery/g3.JPG";
import gallery4 from "../../assets/images/about_gallery/g4.JPG";
import gallery5 from "../../assets/images/about_gallery/g5.jpg";
import gallery6 from "../../assets/images/about_gallery/g6.jpg";
import gallery7 from "../../assets/images/about_gallery/g7.JPG";
import gallery8 from "../../assets/images/about_gallery/g8.jpg";
import gallery9 from "../../assets/images/about_gallery/g9.jpg";
import gallery10 from "../../assets/images/about_gallery/g10.jpg";
import gallery11 from "../../assets/images/about_gallery/g11.jpg";
import gallery12 from "../../assets/images/about_gallery/g12.jpg";
import gallery13 from "../../assets/images/about_gallery/g13.jpg";
import gallery14 from "../../assets/images/about_gallery/g14.jpg";
import gallery15 from "../../assets/images/about_gallery/g15.jpg";
import gallery16 from "../../assets/images/about_gallery/g16.jpg";
import gallery17 from "../../assets/images/about_gallery/g17.jpg";
import gallery18 from "../../assets/images/about_gallery/g18.jpg";
import gallery19 from "../../assets/images/about_gallery/g19.jpg";
import gallery20 from "../../assets/images/about_gallery/g20.jpg";
import gallery21 from "../../assets/images/about_gallery/g21.jpg";
import gallery22 from "../../assets/images/about_gallery/g22.jpg";
import gallery23 from "../../assets/images/about_gallery/g23.jpg";
import gallery24 from "../../assets/images/about_gallery/g24.jpg";
import gallery25 from "../../assets/images/about_gallery/g25.jpg";
import gallery26 from "../../assets/images/about_gallery/g26.jpg";
import gallery27 from "../../assets/images/about_gallery/g27.jpg";
import gallery28 from "../../assets/images/about_gallery/g28.jpg";
import gallery29 from "../../assets/images/about_gallery/g29.jpg";
import gallery30 from "../../assets/images/about_gallery/g30.jpg";
import gallery31 from "../../assets/images/about_gallery/g31.jpg";
import gallery32 from "../../assets/images/about_gallery/g32.jpg";
import gallery33 from "../../assets/images/about_gallery/g33.jpg";
import gallery34 from "../../assets/images/about_gallery/g35.jpg";
import gallery35 from "../../assets/images/about_gallery/g36.jpg";
import gallery36 from "../../assets/images/about_gallery/g37.jpg";
import { useNavigate } from "react-router-dom";

const AboutGallery = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={GalleryBanner}
          title="About Us - Gallery"
          desc="Capturing the Spirit of Sitare"
        />
      </section>

      {/* Section: About Gallery Text */}
      <section className="global-aboutwrapper pb-0">
        <div className="container">
        <div className="row">
               
                <div className="col-12">
                  <p className="global-bodytext">
                  Explore the vibrant life at Sitare University through our gallery! From academic milestones to cultural celebrations, sports events, and inspiring moments, our gallery captures the essence of our dynamic campus and its incredible community.
                  </p>
                </div>
              </div>
        </div>
      </section>



      {/* Section: Gallery Filter */}
      <section className="global-aboutwrapper">
        {/* <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="al-filters">
                <div className="al-filters--wrapper">
                <p className="mb-0 bl-filters--text">Filter by</p>
                <select className="al-filters--select">
                  <option>This Year</option>
                  <option>Year 1</option>
                  <option>Year 2</option>
                </select>
                <select className="al-filters--select">
                  <option>Event Name</option>
                  <option>Topic 1</option>
                  <option>Topic 2</option>
                </select>
                </div>
              

                <button type="button" className="mb-0 al-filters--text">Reset Filters</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* Section: Gallery Cards */}
        <div className="container mb-5">
          {/* <div className="row">
            <div className="col-12">
              <h1 className="global-heading global-fwbold my-5">
                Gallery
              </h1>
            </div>
          </div> */}

          <div className="row">
            <div className="col-12">
              <div className="al-gallery">
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery4} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery5} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery6} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery7} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery8} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery9} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery10} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery11} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery12} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery13} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery14} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery15} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery16} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery17} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery18} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery19} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery20} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery21} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery22} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery23} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery24} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery25} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery26} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery27} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery28} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery29} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery30} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery31} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery32} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery33} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery34} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery35} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery36} alt="gallery-image" draggable="false" />
                  </div>
              </div>
            </div>
          </div>
        </div>

         {/* Section: Gallery Cards */}
         {/* <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading global-fwbold my-5">
                Coffee Club 2023
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="al-gallery">
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default AboutGallery;

import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import thumbnail from "../../assets/images/hostel.png";
import BannerImg from "../../assets/images/hackathon.png";
import PedagogyImg from "../../assets/images/pedagogy_img.png";
import sponser from "../../assets/images/class_room.png";
import "./style.scss";
import "../../assets/styles/styles.scss";
import { Link } from "react-router-dom";

const Hackathon = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="University Life - Events & Activities - Hackathons"
          desc="Code, Create, Compete."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Real World Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Coding for Change: Sitare Hackathons
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    Sitare University hosts regular hackathons as a core
                    component of its Computer Science program. These events
                    provide students with an exhilarating opportunity to engage
                    in intensive coding and problem-solving sessions. Hackathons
                    are designed to simulate real-world pressures and encourage
                    innovative thinking under time constraints. Students form
                    teams to tackle challenges set by industry partners,
                    academic staff, or societal needs, often resulting in
                    groundbreaking solutions and prototypes. Hackathons also
                    serve as a platform for students at Sitare University to
                    network with industry professionals, receive mentorship, and
                    gain insights into current technological trends, further
                    bridging the gap between academic learning and professional
                    practice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Image Banner */}
      <section className="sh-image">
        <div className="container">
          <img
            src={PedagogyImg}
            alt="Pedagogy Image"
            className="sh-image--banner"
          />
        </div>
      </section>

      {/* Section: Upcoming Hackathon heading*/}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div className="">
              <h1 className="global-heading mb-4">Upcoming Hackathons</h1>
              <p className="global-bodytext py-3">
                Stay tuned for our next exciting hackathon! This section will
                include details on upcoming events, themes, and how to
                participate. Whether you're a first-time coder or a seasoned
                developer, there's a challenge for everyone.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Project Box 1 */}
      <section className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="sh-project">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="sh-project--thumbnail">
                    <img
                      src={thumbnail}
                      alt="featured project"
                      draggable="false"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <h3 className="global-subheading global-fw600 text-capitalize mb-4">
                    Student Project name
                  </h3>
                  {/* View More Button */}
                  <div className="mb-5">
                    <Link to={"/"} className="global-orangeBtn">
                      View More Details
                    </Link>
                  </div>

                  {/* Project Detail Text */}
                  <div className="row mb-5">
                    <div className="col-md-6 col-12">
                      <b className="global-label global-fw600">
                        Faculty Mentor:
                      </b>
                      <span className="global-label">Vibin K Venugopal</span>
                    </div>
                    <div className="col-md-6 col-12">
                      {/* Project Detail Text */}
                      <b className="global-label global-fw600">Student Name:</b>
                      <span className="global-label">
                        Anil P, Suresh K, Vignesh P, Suraj H
                      </span>
                    </div>
                  </div>

                  {/* Project Detail Text */}
                  <div>
                    <p className="global-bodytext">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>

                  {/* Sponsers */}
                  <div>
                    <h3 className="global-subheading global-fw600 my-3">
                      Sponsors
                    </h3>
                    <ul className="sh-sponser--list">
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Project Box 2 */}
      <section className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="sh-project">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="sh-project--thumbnail">
                    <img
                      src={thumbnail}
                      alt="featured project"
                      draggable="false"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <h3 className="global-subheading global-fw600 text-capitalize mb-4">
                    Student Project name
                  </h3>
                  {/* View More Button */}
                  <div className="mb-5">
                    <Link to={"/"} className="global-orangeBtn">
                      View More Details
                    </Link>
                  </div>

                  {/* Project Detail Text */}
                  <div className="row mb-5">
                    <div className="col-md-6 col-12">
                      <b className="global-label global-fw600">
                        Faculty Mentor:
                      </b>
                      <span className="global-label">Vibin K Venugopal</span>
                    </div>
                    <div className="col-md-6 col-12">
                      {/* Project Detail Text */}
                      <b className="global-label global-fw600">Student Name:</b>
                      <span className="global-label">
                        Anil P, Suresh K, Vignesh P, Suraj H
                      </span>
                    </div>
                  </div>

                  {/* Project Detail Text */}
                  <div>
                    <p className="global-bodytext">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>

                  {/* Sponsers */}
                  <div>
                    <h3 className="global-subheading global-fw600 my-3">
                      Sponsors
                    </h3>
                    <ul className="sh-sponser--list">
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Past Hackathons heading*/}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div>
              <h1 className="global-heading mb-4">Past Hackathons</h1>
              <p className="global-bodytext py-3">
                Take a look at some of our previous hackathons, where students
                tackled challenges like developing AI-driven solutions for
                healthcare or creating sustainable tech for environmental
                conservation. Here, you can find summaries, winning teams, and
                innovative projects that came out of these events.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Hackathon Event Box 1 */}
      <section className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="sh-project sh-bgblue">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="sh-project--thumbnail">
                    <img
                      src={thumbnail}
                      alt="featured project"
                      draggable="false"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <h3 className="global-subheading global-fw600 text-capitalize mb-4">
                    Hackathon Event Name
                  </h3>
                  {/* View More Button */}
                  <div className="mb-5">
                    <Link to={"/"} className="global-orangeBtn">
                      View More Details
                    </Link>
                  </div>

                  {/* Project Detail Text */}
                  <div className="row mb-5">
                    <div className="col-md-6 col-12">
                      <b className="global-label global-fw600">Date</b>
                      <span className="global-label">DD/MM/YY</span>
                    </div>
                    <div className="col-md-6 col-12">
                      {/* Project Detail Text */}
                      <b className="global-label global-fw600">Mode</b>
                      <span className="global-label">Online</span>
                    </div>
                  </div>

                  {/* Project Detail Text */}
                  <div>
                    <p className="global-bodytext">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>

                  {/* Sponsers */}
                  <div>
                    <h3 className="global-subheading global-fw600 my-3">
                      Sponsors
                    </h3>
                    <ul className="sh-sponser--list">
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                      <li>
                        <img src={sponser} alt="sponser" draggable="false" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: How It Works */}
      <section className="global-blue--strip mt-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">
                Curious about how a hackathon unfolds
              </p>
              <h2 className="global-blue--stripapplybtn">How it Works</h2>
            </div>
            <i className="fa-solid fa-arrow-right global-blue--striparrow"></i>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hackathon;

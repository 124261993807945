import React from "react";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import placementBanner from "../../assets/images/placement/placeBanner.png";
import MenuCards from "../../components/sections/MenuCards";
import { PLACEMENTS_CARDS_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const Placements = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      <section className="mb-md-5">
        <SecondaryBanner
          imageUrl={placementBanner}
          title="Placements"
          desc="Empowering Careers Through Practical Learning."
          themeColor="sh-contentimgsplit--red700-theme"
        />
      </section>

      {/* Section: Intro Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-md-5">
                Internship Odyssey: Mapping the Stars of Tomorrow
              </h1>
            </div>
            <div className="col-12">
              <p className="global-bodytext">
                At Sitare University, we see every student as a luminary, ready
                to light up the professional cosmos. Our three-tier internship
                program is your personal star map, charting a transformative
                journey through discovery, mastery, and global impact.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    1<sup>st</sup> Year: Igniting Your North Star
                  </b>
                  <p className="global-bodytext">
                    The journey begins with your first internship, a beacon
                    guiding you into the vast expanse of industry opportunities.
                    This initial phase bridges the classroom and the workplace,
                    helping you discover your strengths and align your potential
                    with real-world challenges.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    2<sup>nd</sup> Year: Crafting Your Constellation
                  </b>
                  <p className="global-bodytext">
                    By the second year, your second internship becomes the
                    foundation of your professional constellation. Here, you
                    refine your skills, navigate complex challenges, and build
                    resilience, ensuring you are prepared to excel in dynamic
                    environments.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    3<sup>rd</sup> Year: A Year in the Orbit of Excellence
                  </b>
                  <p className="global-bodytext mb-3">
                    In the third year, the journey crescendos with an exclusive
                    12-month internship with trailblazing startups in India and
                    the Silicon Valley, USA. Collaborate with global innovators,
                    immerse yourself in advanced technologies, and experience
                    the entrepreneurial pulse of two of the world’s most dynamic
                    ecosystems.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="global-aboutwrapper py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="global-bodytext">
                At Sitare, your internship journey is not just a passage, it’s
                an odyssey. With every step, we equip you with the tools,
                connections, and experiences to emerge as a leader poised to
                redefine the horizon. The stars are within your reach, let
                Sitare guide your ascent.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section:Menu Cards */}
      <section className="container py-lg-5 mb-5">
        <MenuCards
          data={PLACEMENTS_CARDS_DATA}
          handleMenuClick={handleMenuClick}
        />
      </section>
    </>
  );
};

export default Placements;

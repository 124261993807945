import React from "react";
import "./style.scss";
import FacilityImg from "../../assets/images/university/learning.jpg";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const Facilities = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      <section className="mb-5">
        <SecondaryBanner
          imageUrl={FacilityImg}
          title="University Life - Facilities"
          desc="Campus Experience: Where Learning Meets Living."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>
      {/* Section: Text */}
      <section className="global-aboutwrapper notop">
        <div className="container">
        <div className="row">
                <div className="col-12">
                  <h1 className="global-heading mb-md-5">
                  Where Every Star Finds Its Orbit
                  </h1>
                </div>
                <div className="col-12">
                  <p className="global-bodytext mb-4">
                  At Sitare University, every student is a star, and our campus is the galaxy where they shine the brightest. Like stars in motion, our students are constantly evolving, growing, and discovering new horizons. Just as constellations light up the night sky, our vibrant community connects diverse minds to shape a universe of innovation, creativity, and collaboration.
                  </p>
                  <p className="global-bodytext mb-4">
                  Here, each moment is a new orbit of discovery, whether it's excelling in academics, exploring passions, or forming lifelong bonds. Like the cosmos, our opportunities are limitless, and every step is a leap toward your brightest future.
                  </p>
                  <p className="global-bodytext mb-4">
                  Join Sitare University, where stars like you don’t just belong, you illuminate the world.
                  </p>
                </div>
              </div>
        </div>
      </section>
      <section className="container py-lg-5 mb-5 se-cards">
        <MenuCards
          data={FACILITIES_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--orange-theme"
        />
      </section>
    </>
  );
};

export default Facilities;
